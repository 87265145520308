import axios from "axios";
export const NEXT_BASE_URL = "https://pionechain.tocuna.com/";
const API = axios.create({
  baseURL: NEXT_BASE_URL,
});

export const ContactService = {
  doCreateContact: (data) =>
    API.post("api/v1/user/contact/create-contact", data),
};
