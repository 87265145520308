import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ContactService } from "../api";

export const actionCreateContact = createAsyncThunk(
  "contact/create-contact",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ContactService.doCreateContact(data);

      if (!res.data.status) {
        toast.success(res.data.message);
        return res.data;
      } else {
        toast.error(res.data.message);
        return rejectWithValue(res.data);
      }
    } catch (error) {
      const message = error.response?.data?.message || error.message;
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const ContactSlice = createSlice({
  name: "contact",
  initialState: {
    createContact: {
      loading: false,
      data: null,
      error: "",
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // create Contact
      .addCase(actionCreateContact.pending, (state) => {
        state.createContact.loading = true;
      })
      .addCase(actionCreateContact.fulfilled, (state, action) => {
        state.createContact.loading = false;
        state.createContact.data = action.payload;
      })
      .addCase(actionCreateContact.rejected, (state, action) => {
        state.createContact.loading = "failed";
        state.createContact.data = action.payload;
        state.createContact.error =
          action.error.message || "Something went wrong";
      });
  },
});

export default ContactSlice.reducer;
export const { actions, reducer } = ContactSlice;
